export default [
  {
    "code": "4TL",
    "name": "Toyota"
  },
  {
    "code": "JMG",
    "name": "Mazda"
  },
  {
    "code": "3GE",
    "name": "General Motors Mexico"
  },
  {
    "code": "5TP",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5F5",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "5LH",
    "name": "Lincoln"
  },
  {
    "code": "1GP",
    "name": "General Motors USA"
  },
  {
    "code": "KL9",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "6AB",
    "name": "MAN Australia"
  },
  {
    "code": "JMV",
    "name": "Mazda"
  },
  {
    "code": "JM5",
    "name": "Mazda"
  },
  {
    "code": "JFL",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "5FX",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1HK",
    "name": "Honda USA"
  },
  {
    "code": "JAL",
    "name": "Isuzu"
  },
  {
    "code": "MA1",
    "name": "Mahindra"
  },
  {
    "code": "1NT",
    "name": "Nissan USA"
  },
  {
    "code": "2M7",
    "name": "Mercury"
  },
  {
    "code": "JSC",
    "name": "Suzuki"
  },
  {
    "code": "VF6",
    "name": "Renault (Trucks & Buses)"
  },
  {
    "code": "JM1",
    "name": "Mazda"
  },
  {
    "code": "1N2",
    "name": "Nissan USA"
  },
  {
    "code": "2TK",
    "name": "Toyota Canada"
  },
  {
    "code": "1LZ",
    "name": "Lincoln USA"
  },
  {
    "code": "2MC",
    "name": "Mercury"
  },
  {
    "code": "JMM",
    "name": "Mazda"
  },
  {
    "code": "2GZ",
    "name": "General Motors Canada"
  },
  {
    "code": "JMT",
    "name": "Mazda"
  },
  {
    "code": "3NM",
    "name": "Nissan Mexico"
  },
  {
    "code": "ZFC",
    "name": "Fiat V.I."
  },
  {
    "code": "4ML",
    "name": "Mercury"
  },
  {
    "code": "5FL",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1NM",
    "name": "Nissan USA"
  },
  {
    "code": "WV2",
    "name": "Volkswagen Bus/Van"
  },
  {
    "code": "5FM",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JSV",
    "name": "Suzuki"
  },
  {
    "code": "JM6",
    "name": "Mazda"
  },
  {
    "code": "5L9",
    "name": "Lincoln"
  },
  {
    "code": "1N6",
    "name": "Nissan USA"
  },
  {
    "code": "3P3",
    "name": "Plymouth Mexico"
  },
  {
    "code": "JAT",
    "name": "Isuzu"
  },
  {
    "code": "1GX",
    "name": "General Motors USA"
  },
  {
    "code": "JNZ",
    "name": "Nissan"
  },
  {
    "code": "TMT",
    "name": "Tatra (Czech Republic)"
  },
  {
    "code": "2GD",
    "name": "General Motors Canada"
  },
  {
    "code": "4T5",
    "name": "Toyota"
  },
  {
    "code": "1FA",
    "name": "Ford Motor Company"
  },
  {
    "code": "JHM",
    "name": "Honda"
  },
  {
    "code": "4TZ",
    "name": "Toyota"
  },
  {
    "code": "JNJ",
    "name": "Nissan"
  },
  {
    "code": "KPA",
    "name": "Ssangyong"
  },
  {
    "code": "1H7",
    "name": "Honda USA"
  },
  {
    "code": "JT0",
    "name": "Toyota"
  },
  {
    "code": "1GV",
    "name": "General Motors USA"
  },
  {
    "code": "4SM",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JAH",
    "name": "Isuzu"
  },
  {
    "code": "4FA",
    "name": "Mazda USA"
  },
  {
    "code": "JTE",
    "name": "Toyota"
  },
  {
    "code": "4TU",
    "name": "Toyota"
  },
  {
    "code": "2G6",
    "name": "General Motors Canada"
  },
  {
    "code": "4MF",
    "name": "Mercury"
  },
  {
    "code": "1FC",
    "name": "Ford Motor Company"
  },
  {
    "code": "4ST",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JAA",
    "name": "Isuzu"
  },
  {
    "code": "1H4",
    "name": "Honda USA"
  },
  {
    "code": "4S7",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JTD",
    "name": "Toyota"
  },
  {
    "code": "JMB",
    "name": "Mazda"
  },
  {
    "code": "4SR",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "2GK",
    "name": "General Motors Canada"
  },
  {
    "code": "5FB",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "5LV",
    "name": "Lincoln"
  },
  {
    "code": "2M1",
    "name": "Mercury"
  },
  {
    "code": "4SB",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "4MK",
    "name": "Mercury"
  },
  {
    "code": "2TR",
    "name": "Toyota Canada"
  },
  {
    "code": "LVS",
    "name": "Ford Chang An"
  },
  {
    "code": "JN3",
    "name": "Nissan"
  },
  {
    "code": "1L8",
    "name": "Lincoln USA"
  },
  {
    "code": "4TH",
    "name": "Toyota"
  },
  {
    "code": "3GK",
    "name": "General Motors Mexico"
  },
  {
    "code": "JKY",
    "name": "Kawasaki"
  },
  {
    "code": "1HR",
    "name": "Honda USA"
  },
  {
    "code": "3GR",
    "name": "General Motors Mexico"
  },
  {
    "code": "JAW",
    "name": "Isuzu"
  },
  {
    "code": "4US",
    "name": "BMW USA"
  },
  {
    "code": "1HT",
    "name": "Honda USA"
  },
  {
    "code": "4TX",
    "name": "Toyota"
  },
  {
    "code": 935,
    "name": "Citroën Brazil"
  },
  {
    "code": "3G4",
    "name": "General Motors Mexico"
  },
  {
    "code": "4F7",
    "name": "Mazda USA"
  },
  {
    "code": "2G1",
    "name": "Chevrolet Canada"
  },
  {
    "code": "JS2",
    "name": "Suzuki"
  },
  {
    "code": "3G9",
    "name": "General Motors Mexico"
  },
  {
    "code": "3NL",
    "name": "Nissan Mexico"
  },
  {
    "code": "JSR",
    "name": "Suzuki"
  },
  {
    "code": "1LL",
    "name": "Lincoln USA"
  },
  {
    "code": "JA7",
    "name": "Isuzu"
  },
  {
    "code": "1HJ",
    "name": "Honda USA"
  },
  {
    "code": "KLE",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JKU",
    "name": "Kawasaki"
  },
  {
    "code": "4SK",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JF6",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "3HH",
    "name": "Honda Mexico"
  },
  {
    "code": "8AJ",
    "name": "Toyota Argentina"
  },
  {
    "code": "LZG",
    "name": "Shaanxi Automobile Group"
  },
  {
    "code": "2GC",
    "name": "General Motors Canada"
  },
  {
    "code": "5TN",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JS3",
    "name": "Suzuki"
  },
  {
    "code": "WDB",
    "name": "Mercedes-Benz"
  },
  {
    "code": "3N0",
    "name": "Nissan Mexico"
  },
  {
    "code": "W0L",
    "name": "Opel"
  },
  {
    "code": "MMM",
    "name": "Chevrolet Thailand"
  },
  {
    "code": "5LT",
    "name": "Lincoln"
  },
  {
    "code": "1G1",
    "name": "Chevrolet USA"
  },
  {
    "code": "4SC",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JKT",
    "name": "Kawasaki"
  },
  {
    "code": "L56",
    "name": "Renault Samsung"
  },
  {
    "code": "4T3",
    "name": "Toyota"
  },
  {
    "code": "2MT",
    "name": "Mercury"
  },
  {
    "code": "LZY",
    "name": "Yutong Zhengzhou"
  },
  {
    "code": "JKR",
    "name": "Kawasaki"
  },
  {
    "code": "VF1",
    "name": "Renault"
  },
  {
    "code": "SCE",
    "name": "DeLorean Motor Cars N. Ireland (UK)"
  },
  {
    "code": "JM3",
    "name": "Mazda"
  },
  {
    "code": "4T4",
    "name": "Toyota"
  },
  {
    "code": "JSH",
    "name": "Suzuki"
  },
  {
    "code": "4FU",
    "name": "Mazda USA"
  },
  {
    "code": "5T4",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5L8",
    "name": "Lincoln"
  },
  {
    "code": "2TL",
    "name": "Toyota Canada"
  },
  {
    "code": "JKL",
    "name": "Kawasaki"
  },
  {
    "code": "4F2",
    "name": "Mazda USA"
  },
  {
    "code": "JSN",
    "name": "Suzuki"
  },
  {
    "code": "JF9",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "3HW",
    "name": "Honda Mexico"
  },
  {
    "code": "1NF",
    "name": "Nissan USA"
  },
  {
    "code": "3FE",
    "name": "Ford Motor Company Mexico"
  },
  {
    "code": "UU1",
    "name": "Renault Dacia"
  },
  {
    "code": "1M2",
    "name": "Mack Truck USA"
  },
  {
    "code": "1NV",
    "name": "Nissan USA"
  },
  {
    "code": "6H8",
    "name": "General Motors-Holden (pre Nov 2002)"
  },
  {
    "code": "3HG",
    "name": "Honda Mexico"
  },
  {
    "code": "JND",
    "name": "Nissan"
  },
  {
    "code": "3GT",
    "name": "General Motors Mexico"
  },
  {
    "code": "3H3",
    "name": "Honda Mexico"
  },
  {
    "code": "JAX",
    "name": "Isuzu"
  },
  {
    "code": "JNA",
    "name": "Nissan"
  },
  {
    "code": "KL3",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "VNE",
    "name": "Irisbus (France)"
  },
  {
    "code": "1N5",
    "name": "Nissan USA"
  },
  {
    "code": "3GC",
    "name": "General Motors Mexico"
  },
  {
    "code": "WF0",
    "name": "Ford Germany"
  },
  {
    "code": "3HN",
    "name": "Honda Mexico"
  },
  {
    "code": "4SA",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JN8",
    "name": "Nissan"
  },
  {
    "code": "4V4",
    "name": "Volvo"
  },
  {
    "code": "2GW",
    "name": "General Motors Canada"
  },
  {
    "code": "4FC",
    "name": "Mazda USA"
  },
  {
    "code": "AAV",
    "name": "Volkswagen South Africa"
  },
  {
    "code": "JT3",
    "name": "Toyota"
  },
  {
    "code": "JAS",
    "name": "Isuzu"
  },
  {
    "code": "4FY",
    "name": "Mazda USA"
  },
  {
    "code": "4TE",
    "name": "Toyota"
  },
  {
    "code": "JN2",
    "name": "Nissan"
  },
  {
    "code": "3N1",
    "name": "Nissan Mexico"
  },
  {
    "code": "1ME",
    "name": "Mercury USA"
  },
  {
    "code": "3HV",
    "name": "Honda Mexico"
  },
  {
    "code": "KNA",
    "name": "Kia"
  },
  {
    "code": "9BF",
    "name": "Ford Motor Company Brazil"
  },
  {
    "code": "2TD",
    "name": "Toyota Canada"
  },
  {
    "code": "JK6",
    "name": "Kawasaki"
  },
  {
    "code": "4SS",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JAB",
    "name": "Isuzu"
  },
  {
    "code": "JNT",
    "name": "Nissan"
  },
  {
    "code": "YS3",
    "name": "Saab"
  },
  {
    "code": "KLL",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "2WL",
    "name": "Western Star"
  },
  {
    "code": "1HG",
    "name": "Honda USA"
  },
  {
    "code": "3GH",
    "name": "General Motors Mexico"
  },
  {
    "code": "8AG",
    "name": "Chevrolet Argentina"
  },
  {
    "code": "2MF",
    "name": "Mercury"
  },
  {
    "code": "1YV",
    "name": "Mazda USA (AutoAlliance International)"
  },
  {
    "code": "2TW",
    "name": "Toyota Canada"
  },
  {
    "code": "JNY",
    "name": "Nissan"
  },
  {
    "code": "1LM",
    "name": "Lincoln USA"
  },
  {
    "code": "5TY",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JSM",
    "name": "Suzuki"
  },
  {
    "code": "3NU",
    "name": "Nissan Mexico"
  },
  {
    "code": "5TC",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5T1",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "4S1",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "1LS",
    "name": "Lincoln USA"
  },
  {
    "code": "2FA",
    "name": "Ford Motor Company Canada"
  },
  {
    "code": "JM4",
    "name": "Mazda"
  },
  {
    "code": "5TD",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5FV",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JAP",
    "name": "Isuzu"
  },
  {
    "code": "JTY",
    "name": "Toyota"
  },
  {
    "code": "3GY",
    "name": "General Motors Mexico"
  },
  {
    "code": "5LD",
    "name": "Lincoln"
  },
  {
    "code": "2TJ",
    "name": "Toyota Canada"
  },
  {
    "code": "8AD",
    "name": "Peugeot Argentina"
  },
  {
    "code": "4SZ",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JF1",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JNB",
    "name": "Nissan"
  },
  {
    "code": "2GP",
    "name": "General Motors Canada"
  },
  {
    "code": "JSB",
    "name": "Suzuki"
  },
  {
    "code": "JTB",
    "name": "Toyota"
  },
  {
    "code": "TDM",
    "name": "QUANTYA Swiss Electric Movement (Switzerland)"
  },
  {
    "code": "4MP",
    "name": "Mercury"
  },
  {
    "code": "JAD",
    "name": "Isuzu"
  },
  {
    "code": "4T8",
    "name": "Toyota"
  },
  {
    "code": "JN5",
    "name": "Nissan"
  },
  {
    "code": "5T9",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1GJ",
    "name": "General Motors USA"
  },
  {
    "code": "3GU",
    "name": "General Motors Mexico"
  },
  {
    "code": "JKC",
    "name": "Kawasaki"
  },
  {
    "code": "JK1",
    "name": "Kawasaki"
  },
  {
    "code": "4FB",
    "name": "Mazda USA"
  },
  {
    "code": "JAU",
    "name": "Isuzu"
  },
  {
    "code": "6G1",
    "name": "General Motors-Holden (post Nov 2002)"
  },
  {
    "code": "JMD",
    "name": "Mazda"
  },
  {
    "code": "3GX",
    "name": "General Motors Mexico"
  },
  {
    "code": "VF3",
    "name": "Peugeot"
  },
  {
    "code": "ZAM",
    "name": "Maserati Biturbo"
  },
  {
    "code": "2GL",
    "name": "General Motors Canada"
  },
  {
    "code": "1D3",
    "name": "Dodge"
  },
  {
    "code": "1XP",
    "name": "Peterbilt USA"
  },
  {
    "code": "3HX",
    "name": "Honda Mexico"
  },
  {
    "code": "3GF",
    "name": "General Motors Mexico"
  },
  {
    "code": "TRU",
    "name": "Audi Hungary"
  },
  {
    "code": "5LS",
    "name": "Lincoln"
  },
  {
    "code": "2MM",
    "name": "Mercury"
  },
  {
    "code": "JST",
    "name": "Suzuki"
  },
  {
    "code": "2MA",
    "name": "Mercury"
  },
  {
    "code": "TSM",
    "name": "Suzuki"
  },
  {
    "code": "1H1",
    "name": "Honda USA"
  },
  {
    "code": "2GN",
    "name": "General Motors Canada"
  },
  {
    "code": "3GL",
    "name": "General Motors Mexico"
  },
  {
    "code": "1HW",
    "name": "Honda USA"
  },
  {
    "code": "4F4",
    "name": "Mazda USA"
  },
  {
    "code": "2GH",
    "name": "General Motors Canada"
  },
  {
    "code": "3G3",
    "name": "General Motors Mexico"
  },
  {
    "code": "4M6",
    "name": "Mercury"
  },
  {
    "code": "1N3",
    "name": "Nissan USA"
  },
  {
    "code": "JA8",
    "name": "Isuzu"
  },
  {
    "code": "JAE",
    "name": "Isuzu"
  },
  {
    "code": "NM4",
    "name": "Tofas Turk"
  },
  {
    "code": "JFG",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "KNC",
    "name": "Kia"
  },
  {
    "code": "3NG",
    "name": "Nissan Mexico"
  },
  {
    "code": "1HA",
    "name": "Honda USA"
  },
  {
    "code": "1GW",
    "name": "General Motors USA"
  },
  {
    "code": "JM9",
    "name": "Mazda"
  },
  {
    "code": "3HE",
    "name": "Honda Mexico"
  },
  {
    "code": "1NK",
    "name": "Nissan USA"
  },
  {
    "code": "1L2",
    "name": "Lincoln USA"
  },
  {
    "code": "2M3",
    "name": "Mercury"
  },
  {
    "code": "5L5",
    "name": "Lincoln"
  },
  {
    "code": "JTA",
    "name": "Toyota"
  },
  {
    "code": "4M8",
    "name": "Mercury"
  },
  {
    "code": "2C3",
    "name": "Chrysler Canada"
  },
  {
    "code": "XL9",
    "name": "Spyker"
  },
  {
    "code": "JAR",
    "name": "Isuzu"
  },
  {
    "code": "2TT",
    "name": "Toyota Canada"
  },
  {
    "code": "1NA",
    "name": "Nissan USA"
  },
  {
    "code": "4SN",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "3D3",
    "name": "Dodge Mexico"
  },
  {
    "code": "2MN",
    "name": "Mercury"
  },
  {
    "code": "4M4",
    "name": "Mercury"
  },
  {
    "code": "4TB",
    "name": "Toyota"
  },
  {
    "code": "4M1",
    "name": "Mercury"
  },
  {
    "code": "JFF",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "2T5",
    "name": "Toyota Canada"
  },
  {
    "code": "YS2",
    "name": "Scania AB"
  },
  {
    "code": "5T5",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "PE3",
    "name": "Mazda Phillipines"
  },
  {
    "code": "2WK",
    "name": "Western Star"
  },
  {
    "code": "5TR",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JS0",
    "name": "Suzuki"
  },
  {
    "code": "JS1",
    "name": "Suzuki"
  },
  {
    "code": "JS8",
    "name": "Suzuki"
  },
  {
    "code": "5LW",
    "name": "Lincoln"
  },
  {
    "code": "JNC",
    "name": "Nissan"
  },
  {
    "code": "4F3",
    "name": "Mazda USA"
  },
  {
    "code": "1P3",
    "name": "Plymouth USA"
  },
  {
    "code": "4MJ",
    "name": "Mercury"
  },
  {
    "code": "3FA",
    "name": "Ford Motor Company Mexico"
  },
  {
    "code": "1ND",
    "name": "Nissan USA"
  },
  {
    "code": "3N4",
    "name": "Nissan Mexico"
  },
  {
    "code": "3N3",
    "name": "Nissan Mexico"
  },
  {
    "code": "4FK",
    "name": "Mazda USA"
  },
  {
    "code": "1R9",
    "name": "Roadrunner Hay Squeeze USA"
  },
  {
    "code": "2FZ",
    "name": "Sterling"
  },
  {
    "code": "5LX",
    "name": "Lincoln"
  },
  {
    "code": "1GM",
    "name": "Pontiac USA"
  },
  {
    "code": "1GB",
    "name": "General Motors USA"
  },
  {
    "code": "5FW",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "2MR",
    "name": "Mercury"
  },
  {
    "code": "4FL",
    "name": "Mazda USA"
  },
  {
    "code": "1H0",
    "name": "Honda USA"
  },
  {
    "code": "4SH",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "3GJ",
    "name": "General Motors Mexico"
  },
  {
    "code": "1HE",
    "name": "Honda USA"
  },
  {
    "code": "1LA",
    "name": "Lincoln USA"
  },
  {
    "code": "2GB",
    "name": "General Motors Canada"
  },
  {
    "code": "4FE",
    "name": "Mazda USA"
  },
  {
    "code": "4V1",
    "name": "Volvo"
  },
  {
    "code": "JKP",
    "name": "Kawasaki"
  },
  {
    "code": "1L9",
    "name": "Lincoln USA"
  },
  {
    "code": "ZCG",
    "name": "Cagiva SpA"
  },
  {
    "code": "2G3",
    "name": "Oldsmobile Canada"
  },
  {
    "code": "1N1",
    "name": "Nissan USA"
  },
  {
    "code": "JMX",
    "name": "Mazda"
  },
  {
    "code": "1G3",
    "name": "Oldsmobile USA"
  },
  {
    "code": "1L5",
    "name": "Lincoln USA"
  },
  {
    "code": "JN0",
    "name": "Nissan"
  },
  {
    "code": "93H",
    "name": "Honda Brazil"
  },
  {
    "code": "2T0",
    "name": "Toyota Canada"
  },
  {
    "code": "2G4",
    "name": "Buick Canada"
  },
  {
    "code": "KLW",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5TV",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "2MW",
    "name": "Mercury"
  },
  {
    "code": "KLC",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4FP",
    "name": "Mazda USA"
  },
  {
    "code": "2T4",
    "name": "Toyota Canada"
  },
  {
    "code": "TMP",
    "name": "Škoda trolleybuses (Czech Republic)"
  },
  {
    "code": "JS4",
    "name": "Suzuki"
  },
  {
    "code": "JK9",
    "name": "Kawasaki"
  },
  {
    "code": "JAJ",
    "name": "Isuzu"
  },
  {
    "code": "4TG",
    "name": "Toyota"
  },
  {
    "code": "3HY",
    "name": "Honda Mexico"
  },
  {
    "code": "4T0",
    "name": "Toyota"
  },
  {
    "code": "JN7",
    "name": "Nissan"
  },
  {
    "code": "JSK",
    "name": "Suzuki"
  },
  {
    "code": "JN6",
    "name": "Nissan"
  },
  {
    "code": "TK9",
    "name": "SOR (Czech Republic)"
  },
  {
    "code": "WEB",
    "name": "Evobus GmbH (Mercedes-Bus)"
  },
  {
    "code": "3NY",
    "name": "Nissan Mexico"
  },
  {
    "code": "NM0",
    "name": "Ford Turkey"
  },
  {
    "code": "4TY",
    "name": "Toyota"
  },
  {
    "code": "3NK",
    "name": "Nissan Mexico"
  },
  {
    "code": "2MY",
    "name": "Mercury"
  },
  {
    "code": "5LJ",
    "name": "Lincoln"
  },
  {
    "code": "MPA",
    "name": "Isuzu Thailand"
  },
  {
    "code": "JTL",
    "name": "Toyota"
  },
  {
    "code": "4TW",
    "name": "Toyota"
  },
  {
    "code": "4M0",
    "name": "Mercury"
  },
  {
    "code": "4SW",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "3HS",
    "name": "Honda Mexico"
  },
  {
    "code": "2TN",
    "name": "Toyota Canada"
  },
  {
    "code": "4M2",
    "name": "Mercury"
  },
  {
    "code": "4FZ",
    "name": "Mazda USA"
  },
  {
    "code": "MRH",
    "name": "Honda Thailand"
  },
  {
    "code": "1LF",
    "name": "Lincoln USA"
  },
  {
    "code": "2M4",
    "name": "Mercury"
  },
  {
    "code": "2GE",
    "name": "General Motors Canada"
  },
  {
    "code": "JNU",
    "name": "Nissan"
  },
  {
    "code": "1GS",
    "name": "General Motors USA"
  },
  {
    "code": "ZAR",
    "name": "Alfa Romeo"
  },
  {
    "code": "KL1",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JNE",
    "name": "Nissan"
  },
  {
    "code": "2M2",
    "name": "Mercury"
  },
  {
    "code": "VSS",
    "name": "SEAT"
  },
  {
    "code": "JKV",
    "name": "Kawasaki"
  },
  {
    "code": "WAU",
    "name": "Audi"
  },
  {
    "code": "4TT",
    "name": "Toyota"
  },
  {
    "code": "8AK",
    "name": "Suzuki Argentina"
  },
  {
    "code": "1F9",
    "name": "FWD Corp."
  },
  {
    "code": "3H6",
    "name": "Honda Mexico"
  },
  {
    "code": "4S0",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "4MV",
    "name": "Mercury"
  },
  {
    "code": "JNS",
    "name": "Nissan"
  },
  {
    "code": "KLX",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "1NZ",
    "name": "Nissan USA"
  },
  {
    "code": "5TG",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JMH",
    "name": "Mazda"
  },
  {
    "code": "5TF",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5TZ",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "SDB",
    "name": "Peugeot UK"
  },
  {
    "code": "4SG",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JSY",
    "name": "Suzuki"
  },
  {
    "code": "JA2",
    "name": "Isuzu"
  },
  {
    "code": "KLK",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5F2",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "VLU",
    "name": "Scania France"
  },
  {
    "code": "1LG",
    "name": "Lincoln USA"
  },
  {
    "code": "KLZ",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JAF",
    "name": "Isuzu"
  },
  {
    "code": "1GZ",
    "name": "General Motors USA"
  },
  {
    "code": "4SX",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "5TS",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JK7",
    "name": "Kawasaki"
  },
  {
    "code": "JMC",
    "name": "Mazda"
  },
  {
    "code": "5TH",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "MR0",
    "name": "Toyota Thailand"
  },
  {
    "code": "5FE",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "KLP",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "2T8",
    "name": "Toyota Canada"
  },
  {
    "code": "KLV",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4SV",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "1HU",
    "name": "Honda USA"
  },
  {
    "code": "93R",
    "name": "Toyota Brazil"
  },
  {
    "code": "5LL",
    "name": "Lincoln"
  },
  {
    "code": "5TB",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "ZHW",
    "name": "Lamborghini"
  },
  {
    "code": "3GD",
    "name": "General Motors Mexico"
  },
  {
    "code": "JAK",
    "name": "Isuzu"
  },
  {
    "code": "JK5",
    "name": "Kawasaki"
  },
  {
    "code": "ZDM",
    "name": "Ducati Motor Holdings SpA"
  },
  {
    "code": "1LY",
    "name": "Lincoln USA"
  },
  {
    "code": "3N9",
    "name": "Nissan Mexico"
  },
  {
    "code": "4FM",
    "name": "Mazda USA"
  },
  {
    "code": "4MS",
    "name": "Mercury"
  },
  {
    "code": "4F8",
    "name": "Mazda USA"
  },
  {
    "code": "3GA",
    "name": "General Motors Mexico"
  },
  {
    "code": "JNW",
    "name": "Nissan"
  },
  {
    "code": "2MJ",
    "name": "Mercury"
  },
  {
    "code": "JTR",
    "name": "Toyota"
  },
  {
    "code": "JSX",
    "name": "Suzuki"
  },
  {
    "code": "KL0",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JFK",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "1LP",
    "name": "Lincoln USA"
  },
  {
    "code": "2TM",
    "name": "Toyota Canada"
  },
  {
    "code": "3NR",
    "name": "Nissan Mexico"
  },
  {
    "code": "JT6",
    "name": "Toyota"
  },
  {
    "code": "8AF",
    "name": "Ford Motor Company Argentina"
  },
  {
    "code": "1HS",
    "name": "Honda USA"
  },
  {
    "code": "5LA",
    "name": "Lincoln"
  },
  {
    "code": "3G0",
    "name": "General Motors Mexico"
  },
  {
    "code": "5L6",
    "name": "Lincoln"
  },
  {
    "code": "3GZ",
    "name": "General Motors Mexico"
  },
  {
    "code": "5FJ",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1GF",
    "name": "General Motors USA"
  },
  {
    "code": "2MV",
    "name": "Mercury"
  },
  {
    "code": "4MX",
    "name": "Mercury"
  },
  {
    "code": "5FH",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JFV",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "TM9",
    "name": "Škoda trolleybuses (Czech Republic)"
  },
  {
    "code": "2TZ",
    "name": "Toyota Canada"
  },
  {
    "code": "4UZ",
    "name": "Frt-Thomas Bus"
  },
  {
    "code": "JSF",
    "name": "Suzuki"
  },
  {
    "code": "5T8",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1LB",
    "name": "Lincoln USA"
  },
  {
    "code": "KLS",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JHL",
    "name": "Honda"
  },
  {
    "code": "JTH",
    "name": "Toyota"
  },
  {
    "code": "4FX",
    "name": "Mazda USA"
  },
  {
    "code": "1GU",
    "name": "General Motors USA"
  },
  {
    "code": "4T7",
    "name": "Toyota"
  },
  {
    "code": "1H9",
    "name": "Honda USA"
  },
  {
    "code": "3HL",
    "name": "Honda Mexico"
  },
  {
    "code": "2TU",
    "name": "Toyota Canada"
  },
  {
    "code": "JSG",
    "name": "Suzuki"
  },
  {
    "code": "JN4",
    "name": "Nissan"
  },
  {
    "code": "VV9",
    "name": "TAUROSpain"
  },
  {
    "code": "1LH",
    "name": "Lincoln USA"
  },
  {
    "code": "1GH",
    "name": "General Motors USA"
  },
  {
    "code": "KLM",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5LF",
    "name": "Lincoln"
  },
  {
    "code": "1G4",
    "name": "Buick USA"
  },
  {
    "code": "2M6",
    "name": "Mercury"
  },
  {
    "code": "2TG",
    "name": "Toyota Canada"
  },
  {
    "code": "5FZ",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "SCA",
    "name": "Rolls Royce"
  },
  {
    "code": "3NA",
    "name": "Nissan Mexico"
  },
  {
    "code": "JFC",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "4M5",
    "name": "Mercury"
  },
  {
    "code": "VF8",
    "name": "Matra"
  },
  {
    "code": "2FV",
    "name": "Freightliner"
  },
  {
    "code": "VF7",
    "name": "Citroën"
  },
  {
    "code": "3NS",
    "name": "Nissan Mexico"
  },
  {
    "code": "JNG",
    "name": "Nissan"
  },
  {
    "code": "2HK",
    "name": "Honda Canada"
  },
  {
    "code": "5TU",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1GT",
    "name": "GMC Truck USA"
  },
  {
    "code": "SAL",
    "name": "Land Rover"
  },
  {
    "code": "2FT",
    "name": "Ford Motor Company Canada"
  },
  {
    "code": "2MZ",
    "name": "Mercury"
  },
  {
    "code": "2M5",
    "name": "Mercury"
  },
  {
    "code": "JNM",
    "name": "Nissan"
  },
  {
    "code": "3GS",
    "name": "General Motors Mexico"
  },
  {
    "code": "3NF",
    "name": "Nissan Mexico"
  },
  {
    "code": "JSS",
    "name": "Suzuki"
  },
  {
    "code": "JKZ",
    "name": "Kawasaki"
  },
  {
    "code": "JKH",
    "name": "Kawasaki"
  },
  {
    "code": "3H2",
    "name": "Honda Mexico"
  },
  {
    "code": "JKG",
    "name": "Kawasaki"
  },
  {
    "code": "1VW",
    "name": "Volkswagen USA"
  },
  {
    "code": "6FP",
    "name": "Ford Motor Company Australia"
  },
  {
    "code": "JA9",
    "name": "Isuzu"
  },
  {
    "code": "ZAP",
    "name": "Piaggio/Vespa/Gilera"
  },
  {
    "code": "MA7",
    "name": "Honda Siel Cars India"
  },
  {
    "code": "4MZ",
    "name": "Mercury"
  },
  {
    "code": "SHS",
    "name": "Honda UK"
  },
  {
    "code": "8GG",
    "name": "Chevrolet Chile"
  },
  {
    "code": "4FG",
    "name": "Mazda USA"
  },
  {
    "code": "1NW",
    "name": "Nissan USA"
  },
  {
    "code": "1GA",
    "name": "General Motors USA"
  },
  {
    "code": "4F6",
    "name": "Mazda USA"
  },
  {
    "code": "JM7",
    "name": "Mazda"
  },
  {
    "code": "4FJ",
    "name": "Mazda USA"
  },
  {
    "code": "WV1",
    "name": "Volkswagen Commercial Vehicles"
  },
  {
    "code": "2ME",
    "name": "Mercury"
  },
  {
    "code": "4TN",
    "name": "Toyota"
  },
  {
    "code": "1L1",
    "name": "Lincoln USA"
  },
  {
    "code": "3G7",
    "name": "General Motors Mexico"
  },
  {
    "code": "JF4",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "1GY",
    "name": "General Motors USA"
  },
  {
    "code": "5FA",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1H2",
    "name": "Honda USA"
  },
  {
    "code": "VSX",
    "name": "Opel Spain"
  },
  {
    "code": "TMB",
    "name": "Škoda (Czech Republic)"
  },
  {
    "code": "6F5",
    "name": "Kenworth Australia"
  },
  {
    "code": "JSU",
    "name": "Suzuki"
  },
  {
    "code": "2MX",
    "name": "Mercury"
  },
  {
    "code": "JMF",
    "name": "Mazda"
  },
  {
    "code": "5FN",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1NP",
    "name": "Nissan USA"
  },
  {
    "code": "1NB",
    "name": "Nissan USA"
  },
  {
    "code": "2GF",
    "name": "General Motors Canada"
  },
  {
    "code": "LSY",
    "name": "Brilliance Zhonghua"
  },
  {
    "code": "4RK",
    "name": "Nova Bus USA"
  },
  {
    "code": "4V2",
    "name": "Volvo"
  },
  {
    "code": "ZFA",
    "name": "Fiat"
  },
  {
    "code": "3NB",
    "name": "Nissan Mexico"
  },
  {
    "code": "JS5",
    "name": "Suzuki"
  },
  {
    "code": "JT4",
    "name": "Toyota"
  },
  {
    "code": "1LR",
    "name": "Lincoln USA"
  },
  {
    "code": "1GK",
    "name": "General Motors USA"
  },
  {
    "code": "4VL",
    "name": "Volvo"
  },
  {
    "code": "JK0",
    "name": "Kawasaki"
  },
  {
    "code": "JSA",
    "name": "Suzuki"
  },
  {
    "code": "3HA",
    "name": "Honda Mexico"
  },
  {
    "code": "JFE",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "4F0",
    "name": "Mazda USA"
  },
  {
    "code": "4SU",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "1GD",
    "name": "General Motors USA"
  },
  {
    "code": "JTV",
    "name": "Toyota"
  },
  {
    "code": "YV4",
    "name": "Volvo Cars"
  },
  {
    "code": "JAM",
    "name": "Isuzu"
  },
  {
    "code": "1L6",
    "name": "Lincoln USA"
  },
  {
    "code": "JKD",
    "name": "Kawasaki"
  },
  {
    "code": "1FB",
    "name": "Ford Motor Company"
  },
  {
    "code": "3HU",
    "name": "Honda Mexico"
  },
  {
    "code": "3NV",
    "name": "Nissan Mexico"
  },
  {
    "code": "JFH",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "KLT",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4SL",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "3ND",
    "name": "Nissan Mexico"
  },
  {
    "code": "1HM",
    "name": "Honda USA"
  },
  {
    "code": "5FD",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "4MD",
    "name": "Mercury"
  },
  {
    "code": "4MT",
    "name": "Mercury"
  },
  {
    "code": "JTM",
    "name": "Toyota"
  },
  {
    "code": "JF7",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "5F3",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "2WM",
    "name": "Western Star"
  },
  {
    "code": "1LC",
    "name": "Lincoln USA"
  },
  {
    "code": "4SJ",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "XMC",
    "name": "Mitsubishi (NedCar)"
  },
  {
    "code": "JFN",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JA0",
    "name": "Isuzu"
  },
  {
    "code": "KL6",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "KNB",
    "name": "Kia"
  },
  {
    "code": "JTJ",
    "name": "Toyota"
  },
  {
    "code": "JKS",
    "name": "Kawasaki"
  },
  {
    "code": "2G2",
    "name": "Pontiac Canada"
  },
  {
    "code": "KNM",
    "name": "Renault Samsung"
  },
  {
    "code": "2MB",
    "name": "Mercury"
  },
  {
    "code": "5LP",
    "name": "Lincoln"
  },
  {
    "code": "JSD",
    "name": "Suzuki"
  },
  {
    "code": "KL5",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JSJ",
    "name": "Suzuki"
  },
  {
    "code": "5T7",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JTF",
    "name": "Toyota"
  },
  {
    "code": "6G2",
    "name": "Pontiac Australia (GTO & G8)"
  },
  {
    "code": "1M9",
    "name": "Mynatt Truck & Equipment"
  },
  {
    "code": "MAL",
    "name": "Hyundai"
  },
  {
    "code": "9BM",
    "name": "Mercedes-Benz Brazil"
  },
  {
    "code": "JT9",
    "name": "Toyota"
  },
  {
    "code": "KL7",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "WBS",
    "name": "BMW M"
  },
  {
    "code": "JTN",
    "name": "Toyota"
  },
  {
    "code": "ZLA",
    "name": "Lancia"
  },
  {
    "code": "2GS",
    "name": "General Motors Canada"
  },
  {
    "code": "1N8",
    "name": "Nissan USA"
  },
  {
    "code": "1G9",
    "name": "General Motors USA"
  },
  {
    "code": "JTS",
    "name": "Toyota"
  },
  {
    "code": "5FT",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "MM8",
    "name": "Mazda Thailand"
  },
  {
    "code": "3HB",
    "name": "Honda Mexico"
  },
  {
    "code": "1LV",
    "name": "Lincoln USA"
  },
  {
    "code": "1N9",
    "name": "Nissan USA"
  },
  {
    "code": "YS4",
    "name": "Scania Bus"
  },
  {
    "code": "JNL",
    "name": "Nissan"
  },
  {
    "code": "1LJ",
    "name": "Lincoln USA"
  },
  {
    "code": "1HX",
    "name": "Honda USA"
  },
  {
    "code": "4MR",
    "name": "Mercury"
  },
  {
    "code": "4TR",
    "name": "Toyota"
  },
  {
    "code": "3NC",
    "name": "Nissan Mexico"
  },
  {
    "code": "JTX",
    "name": "Toyota"
  },
  {
    "code": "2FM",
    "name": "Ford Motor Company Canada"
  },
  {
    "code": "3GM",
    "name": "General Motors Mexico"
  },
  {
    "code": "JFT",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "SAJ",
    "name": "Jaguar"
  },
  {
    "code": "3N2",
    "name": "Nissan Mexico"
  },
  {
    "code": "KLR",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4MC",
    "name": "Mercury"
  },
  {
    "code": "1G7",
    "name": "General Motors USA"
  },
  {
    "code": "1N4",
    "name": "Nissan USA"
  },
  {
    "code": "1HL",
    "name": "Honda USA"
  },
  {
    "code": "JT5",
    "name": "Toyota"
  },
  {
    "code": "2HG",
    "name": "Honda Canada"
  },
  {
    "code": "JNN",
    "name": "Nissan"
  },
  {
    "code": "2P3",
    "name": "Plymouth Canada"
  },
  {
    "code": "4SY",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "5TT",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1NL",
    "name": "Nissan USA"
  },
  {
    "code": "1HD",
    "name": "Harley-Davidson"
  },
  {
    "code": "JFZ",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JNF",
    "name": "Nissan"
  },
  {
    "code": "1FV",
    "name": "Freightliner"
  },
  {
    "code": "VS6",
    "name": "Ford Spain"
  },
  {
    "code": "JAZ",
    "name": "Isuzu"
  },
  {
    "code": "LKL",
    "name": "Suzhou King Long"
  },
  {
    "code": "JKF",
    "name": "Kawasaki"
  },
  {
    "code": "JKE",
    "name": "Kawasaki"
  },
  {
    "code": "2G8",
    "name": "General Motors Canada"
  },
  {
    "code": "5TW",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "4MU",
    "name": "Mercury"
  },
  {
    "code": "2GV",
    "name": "General Motors Canada"
  },
  {
    "code": "1HN",
    "name": "Honda USA"
  },
  {
    "code": "JS7",
    "name": "Suzuki"
  },
  {
    "code": "5FK",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "5F1",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JS9",
    "name": "Suzuki"
  },
  {
    "code": "ZDF",
    "name": "Ferrari Dino"
  },
  {
    "code": "JNV",
    "name": "Nissan"
  },
  {
    "code": "4T1",
    "name": "Toyota"
  },
  {
    "code": "TN9",
    "name": "Karosa (Czech Republic)"
  },
  {
    "code": "SCC",
    "name": "Lotus Cars"
  },
  {
    "code": "1L3",
    "name": "Lincoln USA"
  },
  {
    "code": "3HF",
    "name": "Honda Mexico"
  },
  {
    "code": "1HZ",
    "name": "Honda USA"
  },
  {
    "code": "2T2",
    "name": "Toyota Canada"
  },
  {
    "code": "1HP",
    "name": "Honda USA"
  },
  {
    "code": "1C6",
    "name": "Chrysler"
  },
  {
    "code": "KLU",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5NP",
    "name": "Hyundai USA"
  },
  {
    "code": "PE1",
    "name": "Ford Phillipines"
  },
  {
    "code": "2TA",
    "name": "Toyota Canada"
  },
  {
    "code": "3G6",
    "name": "General Motors Mexico"
  },
  {
    "code": "4TP",
    "name": "Toyota"
  },
  {
    "code": "5L0",
    "name": "Lincoln"
  },
  {
    "code": "3HK",
    "name": "Honda Mexico"
  },
  {
    "code": "3HT",
    "name": "Honda Mexico"
  },
  {
    "code": "4FN",
    "name": "Mazda USA"
  },
  {
    "code": "1N7",
    "name": "Nissan USA"
  },
  {
    "code": "JAC",
    "name": "Isuzu"
  },
  {
    "code": "3GP",
    "name": "General Motors Mexico"
  },
  {
    "code": "JT8",
    "name": "Toyota"
  },
  {
    "code": "4TJ",
    "name": "Toyota"
  },
  {
    "code": "2T7",
    "name": "Toyota Canada"
  },
  {
    "code": "KL8",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JTZ",
    "name": "Toyota"
  },
  {
    "code": "4T6",
    "name": "Toyota"
  },
  {
    "code": "2ML",
    "name": "Mercury"
  },
  {
    "code": "5N1",
    "name": "Nissan USA"
  },
  {
    "code": "JF0",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "1L0",
    "name": "Lincoln USA"
  },
  {
    "code": "4M9",
    "name": "Mercury"
  },
  {
    "code": "3H9",
    "name": "Honda Mexico"
  },
  {
    "code": "JTT",
    "name": "Toyota"
  },
  {
    "code": "4FW",
    "name": "Mazda USA"
  },
  {
    "code": "NMT",
    "name": "Toyota Turkiye"
  },
  {
    "code": "JFJ",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "4F1",
    "name": "Mazda USA"
  },
  {
    "code": "5TA",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JKX",
    "name": "Kawasaki"
  },
  {
    "code": "4FV",
    "name": "Mazda USA"
  },
  {
    "code": "YV3",
    "name": "Volvo Buses"
  },
  {
    "code": "5FG",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JKN",
    "name": "Kawasaki"
  },
  {
    "code": "5F4",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "4S6",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "MNT",
    "name": "Nissan Thailand"
  },
  {
    "code": "1HY",
    "name": "Honda USA"
  },
  {
    "code": "JT1",
    "name": "Toyota"
  },
  {
    "code": "3N8",
    "name": "Nissan Mexico"
  },
  {
    "code": "2T3",
    "name": "Toyota Canada"
  },
  {
    "code": "YV1",
    "name": "Volvo Cars"
  },
  {
    "code": "1LN",
    "name": "Lincoln USA"
  },
  {
    "code": "JFX",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JSZ",
    "name": "Suzuki"
  },
  {
    "code": "1LE",
    "name": "Lincoln USA"
  },
  {
    "code": "5FF",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1GL",
    "name": "General Motors USA"
  },
  {
    "code": "JK2",
    "name": "Kawasaki"
  },
  {
    "code": "4S4",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "SCF",
    "name": "Aston"
  },
  {
    "code": "9BS",
    "name": "Scania Brazil"
  },
  {
    "code": "8A1",
    "name": "Renault Argentina"
  },
  {
    "code": "5LM",
    "name": "Lincoln"
  },
  {
    "code": "2TE",
    "name": "Toyota Canada"
  },
  {
    "code": "JTG",
    "name": "Toyota"
  },
  {
    "code": "1GC",
    "name": "Chevrolet Truck USA"
  },
  {
    "code": "YV2",
    "name": "Volvo Trucks"
  },
  {
    "code": "JK8",
    "name": "Kawasaki"
  },
  {
    "code": "1LU",
    "name": "Lincoln USA"
  },
  {
    "code": "JK3",
    "name": "Kawasaki"
  },
  {
    "code": "5TX",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "3G8",
    "name": "General Motors Mexico"
  },
  {
    "code": "JS6",
    "name": "Suzuki"
  },
  {
    "code": "JA6",
    "name": "Isuzu"
  },
  {
    "code": "3GV",
    "name": "General Motors Mexico"
  },
  {
    "code": "5LE",
    "name": "Lincoln"
  },
  {
    "code": "1GR",
    "name": "General Motors USA"
  },
  {
    "code": "1N0",
    "name": "Nissan USA"
  },
  {
    "code": "1H6",
    "name": "Honda USA"
  },
  {
    "code": "4S9",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "5L7",
    "name": "Lincoln"
  },
  {
    "code": "5T0",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "8GD",
    "name": "Peugeot Chile"
  },
  {
    "code": "1NX",
    "name": "NUMMI USA"
  },
  {
    "code": "4T2",
    "name": "Toyota"
  },
  {
    "code": "2CN",
    "name": "CAMI"
  },
  {
    "code": "4ME",
    "name": "Mercury"
  },
  {
    "code": "LZM",
    "name": "MAN China"
  },
  {
    "code": "5L3",
    "name": "Lincoln"
  },
  {
    "code": "2FB",
    "name": "Ford Motor Company Canada"
  },
  {
    "code": "1GN",
    "name": "General Motors USA"
  },
  {
    "code": "3HJ",
    "name": "Honda Mexico"
  },
  {
    "code": "2TV",
    "name": "Toyota Canada"
  },
  {
    "code": "KLF",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "1G0",
    "name": "General Motors USA"
  },
  {
    "code": "2MS",
    "name": "Mercury"
  },
  {
    "code": "4MG",
    "name": "Mercury"
  },
  {
    "code": "2D3",
    "name": "Dodge Canada"
  },
  {
    "code": "3H1",
    "name": "Honda Mexico"
  },
  {
    "code": "JSE",
    "name": "Suzuki"
  },
  {
    "code": "5TE",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1LD",
    "name": "Lincoln USA"
  },
  {
    "code": "MA3",
    "name": "Suzuki India"
  },
  {
    "code": "JA5",
    "name": "Isuzu"
  },
  {
    "code": "5LY",
    "name": "Lincoln"
  },
  {
    "code": "93U",
    "name": "Audi Brazil"
  },
  {
    "code": "4S2",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "4FD",
    "name": "Mazda USA"
  },
  {
    "code": "4S8",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JFU",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "2GR",
    "name": "General Motors Canada"
  },
  {
    "code": "YK1",
    "name": "Saab"
  },
  {
    "code": "4SP",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "2GJ",
    "name": "General Motors Canada"
  },
  {
    "code": "3N5",
    "name": "Nissan Mexico"
  },
  {
    "code": "TRA",
    "name": "Ikarus Bus"
  },
  {
    "code": "SJN",
    "name": "Nissan UK"
  },
  {
    "code": "5FS",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JSL",
    "name": "Suzuki"
  },
  {
    "code": "KMH",
    "name": "Hyundai"
  },
  {
    "code": "2M8",
    "name": "Mercury"
  },
  {
    "code": "JFD",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JML",
    "name": "Mazda"
  },
  {
    "code": "2TB",
    "name": "Toyota Canada"
  },
  {
    "code": "4M7",
    "name": "Mercury"
  },
  {
    "code": "9BG",
    "name": "Chevrolet Brazil"
  },
  {
    "code": "1NY",
    "name": "Nissan USA"
  },
  {
    "code": "JAY",
    "name": "Isuzu"
  },
  {
    "code": "1HH",
    "name": "Honda USA"
  },
  {
    "code": "5LK",
    "name": "Lincoln"
  },
  {
    "code": "JMN",
    "name": "Mazda"
  },
  {
    "code": "3H4",
    "name": "Honda Mexico"
  },
  {
    "code": "5L4",
    "name": "Lincoln"
  },
  {
    "code": "3NH",
    "name": "Nissan Mexico"
  },
  {
    "code": "1J4",
    "name": "Jeep"
  },
  {
    "code": "5LN",
    "name": "Lincoln"
  },
  {
    "code": "AFA",
    "name": "Ford South Africa"
  },
  {
    "code": "JTC",
    "name": "Toyota"
  },
  {
    "code": "2GT",
    "name": "General Motors Canada"
  },
  {
    "code": "9FB",
    "name": "Renault Colombia"
  },
  {
    "code": "3H5",
    "name": "Honda Mexico"
  },
  {
    "code": "5LG",
    "name": "Lincoln"
  },
  {
    "code": "MP1",
    "name": "Isuzu Thailand"
  },
  {
    "code": "1FT",
    "name": "Ford Motor Company"
  },
  {
    "code": "JKK",
    "name": "Kawasaki"
  },
  {
    "code": "TMK",
    "name": "Karosa (Czech Republic)"
  },
  {
    "code": "1NN",
    "name": "Nissan USA"
  },
  {
    "code": "2TY",
    "name": "Toyota Canada"
  },
  {
    "code": "4SE",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "5LB",
    "name": "Lincoln"
  },
  {
    "code": "3HC",
    "name": "Honda Mexico"
  },
  {
    "code": "3H7",
    "name": "Honda Mexico"
  },
  {
    "code": "5TM",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1C3",
    "name": "Chrysler"
  },
  {
    "code": "4S3",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "2V4",
    "name": "Volkswagen Canada"
  },
  {
    "code": "6MM",
    "name": "Mitsubishi Motors Australia"
  },
  {
    "code": "1NC",
    "name": "Nissan USA"
  },
  {
    "code": "WMA",
    "name": "MAN Germany"
  },
  {
    "code": "4TA",
    "name": "Toyota"
  },
  {
    "code": "5F9",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "5TJ",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "5FP",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JNH",
    "name": "Nissan"
  },
  {
    "code": "4SF",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "4FS",
    "name": "Mazda USA"
  },
  {
    "code": "5F7",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "SAR",
    "name": "Rover"
  },
  {
    "code": "SFD",
    "name": "Alexander Dennis UK"
  },
  {
    "code": "VSK",
    "name": "Nissan Spain"
  },
  {
    "code": "VX1",
    "name": "Zastava / Yugo Serbia"
  },
  {
    "code": "1HF",
    "name": "Honda USA"
  },
  {
    "code": "1LT",
    "name": "Lincoln USA"
  },
  {
    "code": "1H3",
    "name": "Honda USA"
  },
  {
    "code": "4VZ",
    "name": "Volvo"
  },
  {
    "code": "JNK",
    "name": "Nissan"
  },
  {
    "code": "JM0",
    "name": "Mazda"
  },
  {
    "code": "3NX",
    "name": "Nissan Mexico"
  },
  {
    "code": "2M9",
    "name": "Mercury"
  },
  {
    "code": "2MK",
    "name": "Mercury"
  },
  {
    "code": "2MD",
    "name": "Mercury"
  },
  {
    "code": "6F4",
    "name": "Nissan Motor Company Australia"
  },
  {
    "code": "1M1",
    "name": "Mack Truck USA"
  },
  {
    "code": "XTA",
    "name": "Lada/AutoVaz (Russia)"
  },
  {
    "code": "2MU",
    "name": "Mercury"
  },
  {
    "code": "5LU",
    "name": "Lincoln"
  },
  {
    "code": "8AW",
    "name": "Volkswagen Argentina"
  },
  {
    "code": "KLB",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4V3",
    "name": "Volvo"
  },
  {
    "code": "2TX",
    "name": "Toyota Canada"
  },
  {
    "code": "JF3",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "4VM",
    "name": "Volvo"
  },
  {
    "code": "2MP",
    "name": "Mercury"
  },
  {
    "code": "JFP",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "3GB",
    "name": "General Motors Mexico"
  },
  {
    "code": "5L1",
    "name": "Lincoln"
  },
  {
    "code": "2M0",
    "name": "Mercury"
  },
  {
    "code": "MNB",
    "name": "Ford Thailand"
  },
  {
    "code": "JAN",
    "name": "Isuzu"
  },
  {
    "code": "MMB",
    "name": "Mitsubishi Thailand"
  },
  {
    "code": "2GA",
    "name": "General Motors Canada"
  },
  {
    "code": "PL1",
    "name": "Proton"
  },
  {
    "code": "4MM",
    "name": "Mercury"
  },
  {
    "code": "SU9",
    "name": "Solaris Bus & Coach (Poland)"
  },
  {
    "code": "5T6",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "2MG",
    "name": "Mercury"
  },
  {
    "code": "JKA",
    "name": "Kawasaki"
  },
  {
    "code": "L5Y",
    "name": "Merato Motorcycle Taizhou Zhongneng"
  },
  {
    "code": "3HP",
    "name": "Honda Mexico"
  },
  {
    "code": "2GM",
    "name": "General Motors Canada"
  },
  {
    "code": "4SD",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JFS",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "KLY",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4MB",
    "name": "Mercury"
  },
  {
    "code": "1GE",
    "name": "General Motors USA"
  },
  {
    "code": "JNP",
    "name": "Nissan"
  },
  {
    "code": "KLJ",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JMR",
    "name": "Mazda"
  },
  {
    "code": "JHG",
    "name": "Honda"
  },
  {
    "code": "4MW",
    "name": "Mercury"
  },
  {
    "code": "5FR",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "JKW",
    "name": "Kawasaki"
  },
  {
    "code": "1H5",
    "name": "Honda USA"
  },
  {
    "code": "5T3",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "MC2",
    "name": "Volvo Eicher commercial vehicles limited."
  },
  {
    "code": "KLG",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4FF",
    "name": "Mazda USA"
  },
  {
    "code": "LGH",
    "name": "Dong Feng (DFM)"
  },
  {
    "code": "JA1",
    "name": "Isuzu"
  },
  {
    "code": "JTK",
    "name": "Toyota"
  },
  {
    "code": "4MA",
    "name": "Mercury"
  },
  {
    "code": "4S5",
    "name": "Subaru-Isuzu Automotive"
  },
  {
    "code": "JMY",
    "name": "Mazda"
  },
  {
    "code": "5FY",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "2G9",
    "name": "General Motors Canada"
  },
  {
    "code": "3G1",
    "name": "General Motors Mexico"
  },
  {
    "code": "KL2",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5F0",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "3HR",
    "name": "Honda Mexico"
  },
  {
    "code": "LVV",
    "name": "Chery"
  },
  {
    "code": "KPT",
    "name": "Ssangyong"
  },
  {
    "code": "1L4",
    "name": "Lincoln USA"
  },
  {
    "code": "5FC",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "KLN",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "5F6",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "1NR",
    "name": "Nissan USA"
  },
  {
    "code": "5L2",
    "name": "Lincoln"
  },
  {
    "code": "5T2",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "1FU",
    "name": "Freightliner"
  },
  {
    "code": "WAG",
    "name": "Neoplan"
  },
  {
    "code": "4FH",
    "name": "Mazda USA"
  },
  {
    "code": "JFA",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "1H8",
    "name": "Honda USA"
  },
  {
    "code": "3G5",
    "name": "General Motors Mexico"
  },
  {
    "code": "TSE",
    "name": "Ikarus Egyedi Autobuszgyar"
  },
  {
    "code": "2HM",
    "name": "Hyundai Canada"
  },
  {
    "code": "2T9",
    "name": "Toyota Canada"
  },
  {
    "code": "4TC",
    "name": "Toyota"
  },
  {
    "code": "4MY",
    "name": "Mercury"
  },
  {
    "code": "9BW",
    "name": "Volkswagen Brazil"
  },
  {
    "code": "1NU",
    "name": "Nissan USA"
  },
  {
    "code": "2NV",
    "name": "Nova Bus Canada"
  },
  {
    "code": "5LC",
    "name": "Lincoln"
  },
  {
    "code": "3VW",
    "name": "Volkswagen Mexico"
  },
  {
    "code": "6U9",
    "name": "Privately Imported car in Australia"
  },
  {
    "code": "ZD4",
    "name": "Aprilia"
  },
  {
    "code": "LDY",
    "name": "Zhongtong Coach"
  },
  {
    "code": "KLD",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JT2",
    "name": "Toyota"
  },
  {
    "code": "JTW",
    "name": "Toyota"
  },
  {
    "code": "JAG",
    "name": "Isuzu"
  },
  {
    "code": "KL4",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "JA4",
    "name": "Isuzu"
  },
  {
    "code": "4FR",
    "name": "Mazda USA"
  },
  {
    "code": "4T9",
    "name": "Toyota"
  },
  {
    "code": "4V6",
    "name": "Volvo"
  },
  {
    "code": "LZE",
    "name": "Isuzu Guangzhou"
  },
  {
    "code": "3NP",
    "name": "Nissan Mexico"
  },
  {
    "code": "JMA",
    "name": "Mazda"
  },
  {
    "code": "2G0",
    "name": "General Motors Canada"
  },
  {
    "code": "8AP",
    "name": "Fiat Argentina"
  },
  {
    "code": "1G8",
    "name": "Saturn USA"
  },
  {
    "code": "VSE",
    "name": "Suzuki Spain (Santana Motors)"
  },
  {
    "code": "JMZ",
    "name": "Mazda"
  },
  {
    "code": "4V5",
    "name": "Volvo"
  },
  {
    "code": "5LZ",
    "name": "Lincoln"
  },
  {
    "code": "JFR",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JM2",
    "name": "Mazda"
  },
  {
    "code": "5TL",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "JN9",
    "name": "Nissan"
  },
  {
    "code": "2G5",
    "name": "General Motors Canada"
  },
  {
    "code": "3H0",
    "name": "Honda Mexico"
  },
  {
    "code": "93Y",
    "name": "Renault Brazil"
  },
  {
    "code": "JF8",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JKJ",
    "name": "Kawasaki"
  },
  {
    "code": "JSP",
    "name": "Suzuki"
  },
  {
    "code": "2TF",
    "name": "Toyota Canada"
  },
  {
    "code": "WDC",
    "name": "DaimlerChrysler"
  },
  {
    "code": "WDD",
    "name": "McLaren"
  },
  {
    "code": "3HM",
    "name": "Honda Mexico"
  },
  {
    "code": "WMW",
    "name": "MINI"
  },
  {
    "code": "3NW",
    "name": "Nissan Mexico"
  },
  {
    "code": "2T6",
    "name": "Toyota Canada"
  },
  {
    "code": "MHR",
    "name": "Honda Indonesia"
  },
  {
    "code": "1L7",
    "name": "Lincoln USA"
  },
  {
    "code": "3N7",
    "name": "Nissan Mexico"
  },
  {
    "code": "JFM",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JMJ",
    "name": "Mazda"
  },
  {
    "code": "JM8",
    "name": "Mazda"
  },
  {
    "code": "2TP",
    "name": "Toyota Canada"
  },
  {
    "code": "1GG",
    "name": "General Motors USA"
  },
  {
    "code": "JFY",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JFB",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "3NE",
    "name": "Nissan Mexico"
  },
  {
    "code": "6T1",
    "name": "Toyota Motor Corporation Australia"
  },
  {
    "code": "NLE",
    "name": "Mercedes-Benz Turk Truck"
  },
  {
    "code": "1NG",
    "name": "Nissan USA"
  },
  {
    "code": "1NE",
    "name": "Nissan USA"
  },
  {
    "code": "5TK",
    "name": "Toyota USA - trucks"
  },
  {
    "code": "2TS",
    "name": "Toyota Canada"
  },
  {
    "code": "4F9",
    "name": "Mazda USA"
  },
  {
    "code": "4MN",
    "name": "Mercury"
  },
  {
    "code": "WVW",
    "name": "Volkswagen"
  },
  {
    "code": "JTP",
    "name": "Toyota"
  },
  {
    "code": "4MH",
    "name": "Mercury"
  },
  {
    "code": "KM8",
    "name": "Hyundai"
  },
  {
    "code": "JTU",
    "name": "Toyota"
  },
  {
    "code": "4TF",
    "name": "Toyota"
  },
  {
    "code": "JMS",
    "name": "Mazda"
  },
  {
    "code": "3NN",
    "name": "Nissan Mexico"
  },
  {
    "code": "2TC",
    "name": "Toyota Canada"
  },
  {
    "code": "4TV",
    "name": "Toyota"
  },
  {
    "code": "2T1",
    "name": "Toyota Canada"
  },
  {
    "code": "2GX",
    "name": "General Motors Canada"
  },
  {
    "code": "JK4",
    "name": "Kawasaki"
  },
  {
    "code": "JMU",
    "name": "Mazda"
  },
  {
    "code": "JF5",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "WP0",
    "name": "Porsche"
  },
  {
    "code": "JNX",
    "name": "Nissan"
  },
  {
    "code": "1G5",
    "name": "General Motors USA"
  },
  {
    "code": "JNR",
    "name": "Nissan"
  },
  {
    "code": "JF2",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JKB",
    "name": "Kawasaki"
  },
  {
    "code": "JME",
    "name": "Mazda"
  },
  {
    "code": "JA3",
    "name": "Isuzu"
  },
  {
    "code": "1G2",
    "name": "Pontiac USA"
  },
  {
    "code": "VWV",
    "name": "Volkswagen Spain"
  },
  {
    "code": "2GU",
    "name": "General Motors Canada"
  },
  {
    "code": "4TK",
    "name": "Toyota"
  },
  {
    "code": "1HV",
    "name": "Honda USA"
  },
  {
    "code": "VS9",
    "name": "Carrocerias Ayats (Spain)"
  },
  {
    "code": "1FD",
    "name": "Ford Motor Company"
  },
  {
    "code": "JMP",
    "name": "Mazda"
  },
  {
    "code": "KLH",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "4TD",
    "name": "Toyota"
  },
  {
    "code": "2MH",
    "name": "Mercury"
  },
  {
    "code": "2FC",
    "name": "Ford Motor Company Canada"
  },
  {
    "code": "1G6",
    "name": "Cadillac USA"
  },
  {
    "code": "9BD",
    "name": "Fiat Brazil"
  },
  {
    "code": "3HZ",
    "name": "Honda Mexico"
  },
  {
    "code": "JAV",
    "name": "Isuzu"
  },
  {
    "code": "2G7",
    "name": "General Motors Canada"
  },
  {
    "code": "WBA",
    "name": "BMW"
  },
  {
    "code": "JT7",
    "name": "Toyota"
  },
  {
    "code": "JKM",
    "name": "Kawasaki"
  },
  {
    "code": "3N6",
    "name": "Nissan Mexico"
  },
  {
    "code": "1LX",
    "name": "Lincoln USA"
  },
  {
    "code": "JMK",
    "name": "Mazda"
  },
  {
    "code": "3NT",
    "name": "Nissan Mexico"
  },
  {
    "code": "5F8",
    "name": "Honda USA-Alabama"
  },
  {
    "code": "KLA",
    "name": "Daewoo General Motors South Korea"
  },
  {
    "code": "2GG",
    "name": "General Motors Canada"
  },
  {
    "code": "3GW",
    "name": "General Motors Mexico"
  },
  {
    "code": "4F5",
    "name": "Mazda USA"
  },
  {
    "code": "1M3",
    "name": "Mack Truck USA"
  },
  {
    "code": "1LW",
    "name": "Lincoln USA"
  },
  {
    "code": "4M3",
    "name": "Mercury"
  },
  {
    "code": "3GN",
    "name": "General Motors Mexico"
  },
  {
    "code": "1NS",
    "name": "Nissan USA"
  },
  {
    "code": "1NH",
    "name": "Nissan USA"
  },
  {
    "code": "1XK",
    "name": "Kenworth USA"
  },
  {
    "code": "2TH",
    "name": "Toyota Canada"
  },
  {
    "code": "1HC",
    "name": "Honda USA"
  },
  {
    "code": "1NJ",
    "name": "Nissan USA"
  },
  {
    "code": "JFW",
    "name": "Fuji Heavy Industries"
  },
  {
    "code": "JSW",
    "name": "Suzuki"
  },
  {
    "code": "ZFF",
    "name": "Ferrari"
  },
  {
    "code": "LTV",
    "name": "Toyota Tian Jin"
  },
  {
    "code": "3G2",
    "name": "General Motors Mexico"
  },
  {
    "code": "4FT",
    "name": "Mazda USA"
  },
  {
    "code": "3H8",
    "name": "Honda Mexico"
  },
  {
    "code": "3GG",
    "name": "General Motors Mexico"
  },
  {
    "code": "3HD",
    "name": "Honda Mexico"
  },
  {
    "code": "4TS",
    "name": "Toyota"
  },
  {
    "code": "2FU",
    "name": "Freightliner"
  },
  {
    "code": "1FM",
    "name": "Ford Motor Company"
  },
  {
    "code": "1LK",
    "name": "Lincoln USA"
  },
  {
    "code": "JMW",
    "name": "Mazda"
  },
  {
    "code": "2GY",
    "name": "General Motors Canada"
  },
  {
    "code": 936,
    "name": "Peugeot Brazil"
  },
  {
    "code": "4TM",
    "name": "Toyota"
  },
  {
    "code": "5LR",
    "name": "Lincoln"
  },
  {
    "code": "ZOM",
    "name": "OM"
  },
  {
    "code": "3NJ",
    "name": "Nissan Mexico"
  },
  {
    "code": "MMT",
    "name": "Mitsubishi Thailand"
  },
  {
    "code": "1M4",
    "name": "Mack Truck USA"
  },
  {
    "code": "1HB",
    "name": "Honda USA"
  },
  {
    "code": "3NZ",
    "name": "Nissan Mexico"
  },
  {
    "code": "JN1",
    "name": "Nissan"
  },
  {
    "code": "5FU",
    "name": "Honda USA-Alabama"
  }
]
