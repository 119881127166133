export default [
  {
    "code": "VF",
    "name": "France"
  },
  {
    "code": "1J",
    "name": "United States"
  },
  {
    "code": "AC",
    "name": "United Kingdom"
  },
  {
    "code": "LT",
    "name": "China"
  },
  {
    "code": "ME",
    "name": "India"
  },
  {
    "code": "MC",
    "name": "India"
  },
  {
    "code": "8B",
    "name": "Argentina"
  },
  {
    "code": "2Z",
    "name": "Canada"
  },
  {
    "code": "XL",
    "name": "The Netherlands"
  },
  {
    "code": "2P",
    "name": "Canada"
  },
  {
    "code": "SU",
    "name": "Poland"
  },
  {
    "code": "J8",
    "name": "Japan"
  },
  {
    "code": "LZ",
    "name": "China"
  },
  {
    "code": "6E",
    "name": "Australia"
  },
  {
    "code": "VR",
    "name": "France"
  },
  {
    "code": "WL",
    "name": "Germany"
  },
  {
    "code": "8D",
    "name": "Argentina"
  },
  {
    "code": "4W",
    "name": "United States"
  },
  {
    "code": 81,
    "name": "Venezuela"
  },
  {
    "code": "8C",
    "name": "Argentina"
  },
  {
    "code": "ZC",
    "name": "Italy"
  },
  {
    "code": "4S",
    "name": "United States"
  },
  {
    "code": "6P",
    "name": "Australia"
  },
  {
    "code": "L1",
    "name": "China"
  },
  {
    "code": 22,
    "name": "Canada"
  },
  {
    "code": "VZ",
    "name": "Yugoslavia"
  },
  {
    "code": "WF",
    "name": "Germany"
  },
  {
    "code": "J9",
    "name": "Japan"
  },
  {
    "code": "J3",
    "name": "Japan"
  },
  {
    "code": "JV",
    "name": "Japan"
  },
  {
    "code": "9D",
    "name": "Brazil"
  },
  {
    "code": "LE",
    "name": "China"
  },
  {
    "code": 41,
    "name": "United States"
  },
  {
    "code": "VU",
    "name": "Spain"
  },
  {
    "code": "8Y",
    "name": "Venezuela"
  },
  {
    "code": 59,
    "name": "United States"
  },
  {
    "code": "WH",
    "name": "Germany"
  },
  {
    "code": 55,
    "name": "United States"
  },
  {
    "code": "MK",
    "name": "Indonesia"
  },
  {
    "code": "2E",
    "name": "Canada"
  },
  {
    "code": "WY",
    "name": "Germany"
  },
  {
    "code": "9J",
    "name": "Colombia"
  },
  {
    "code": "PC",
    "name": "Philippines"
  },
  {
    "code": "3T",
    "name": "Mexico"
  },
  {
    "code": "7E",
    "name": "New Zealand"
  },
  {
    "code": "VD",
    "name": "Austria"
  },
  {
    "code": 52,
    "name": "United States"
  },
  {
    "code": "YH",
    "name": "Finland"
  },
  {
    "code": "7A",
    "name": "New Zealand"
  },
  {
    "code": "MR",
    "name": "Thailand"
  },
  {
    "code": "YA",
    "name": "Belgium"
  },
  {
    "code": 95,
    "name": "Brazil"
  },
  {
    "code": 37,
    "name": "Mexico"
  },
  {
    "code": "J1",
    "name": "Japan"
  },
  {
    "code": "JE",
    "name": "Japan"
  },
  {
    "code": "9G",
    "name": "Colombia"
  },
  {
    "code": "3W",
    "name": "Mexico"
  },
  {
    "code": "5P",
    "name": "United States"
  },
  {
    "code": "4D",
    "name": "United States"
  },
  {
    "code": 99,
    "name": "Brazil"
  },
  {
    "code": "3U",
    "name": "Mexico"
  },
  {
    "code": "6W",
    "name": "Australia"
  },
  {
    "code": "ZL",
    "name": "Italy"
  },
  {
    "code": "6T",
    "name": "Australia"
  },
  {
    "code": "VC",
    "name": "Austria"
  },
  {
    "code": "PP",
    "name": "Malaysia"
  },
  {
    "code": "6U",
    "name": "Australia"
  },
  {
    "code": "AH",
    "name": "United Kingdom"
  },
  {
    "code": "3F",
    "name": "Mexico"
  },
  {
    "code": "SY",
    "name": "Poland"
  },
  {
    "code": "JB",
    "name": "Japan"
  },
  {
    "code": "TV",
    "name": "Hungary"
  },
  {
    "code": "1M",
    "name": "United States"
  },
  {
    "code": "W0",
    "name": "Germany"
  },
  {
    "code": "4Y",
    "name": "United States"
  },
  {
    "code": "LJ",
    "name": "China"
  },
  {
    "code": 38,
    "name": "Mexico"
  },
  {
    "code": "4T",
    "name": "United States"
  },
  {
    "code": "3D",
    "name": "Mexico"
  },
  {
    "code": "9B",
    "name": "Brazil"
  },
  {
    "code": "1N",
    "name": "United States"
  },
  {
    "code": 32,
    "name": "Mexico"
  },
  {
    "code": "J2",
    "name": "Japan"
  },
  {
    "code": "ZG",
    "name": "Italy"
  },
  {
    "code": "3H",
    "name": "Mexico"
  },
  {
    "code": "4J",
    "name": "United States"
  },
  {
    "code": "AM",
    "name": "United Kingdom"
  },
  {
    "code": "JX",
    "name": "Japan"
  },
  {
    "code": "8A",
    "name": "Argentina"
  },
  {
    "code": "YD",
    "name": "Belgium"
  },
  {
    "code": "ZH",
    "name": "Italy"
  },
  {
    "code": "SR",
    "name": "Germany"
  },
  {
    "code": "TC",
    "name": "Switzerland"
  },
  {
    "code": "1A",
    "name": "United States"
  },
  {
    "code": "2W",
    "name": "Canada"
  },
  {
    "code": "JP",
    "name": "Japan"
  },
  {
    "code": "AE",
    "name": "United Kingdom"
  },
  {
    "code": "4H",
    "name": "United States"
  },
  {
    "code": "L5",
    "name": "China"
  },
  {
    "code": "W5",
    "name": "Germany"
  },
  {
    "code": "W7",
    "name": "Germany"
  },
  {
    "code": "TA",
    "name": "Switzerland"
  },
  {
    "code": "5V",
    "name": "United States"
  },
  {
    "code": "WA",
    "name": "Germany"
  },
  {
    "code": "ML",
    "name": "Thailand"
  },
  {
    "code": "TU",
    "name": "Hungary"
  },
  {
    "code": "MH",
    "name": "Indonesia"
  },
  {
    "code": 96,
    "name": "Brazil"
  },
  {
    "code": "8E",
    "name": "Argentina"
  },
  {
    "code": "4V",
    "name": "United States"
  },
  {
    "code": "J6",
    "name": "Japan"
  },
  {
    "code": "ST",
    "name": "Germany"
  },
  {
    "code": "WJ",
    "name": "Germany"
  },
  {
    "code": "JR",
    "name": "Japan"
  },
  {
    "code": "YB",
    "name": "Belgium"
  },
  {
    "code": 26,
    "name": "Canada"
  },
  {
    "code": "LG",
    "name": "China"
  },
  {
    "code": "WD",
    "name": "Germany"
  },
  {
    "code": "4E",
    "name": "United States"
  },
  {
    "code": "6V",
    "name": "Australia"
  },
  {
    "code": "WW",
    "name": "Germany"
  },
  {
    "code": "WT",
    "name": "Germany"
  },
  {
    "code": "MD",
    "name": "India"
  },
  {
    "code": 30,
    "name": "Mexico"
  },
  {
    "code": "J4",
    "name": "Japan"
  },
  {
    "code": 46,
    "name": "United States"
  },
  {
    "code": "WV",
    "name": "Germany"
  },
  {
    "code": 36,
    "name": "Mexico"
  },
  {
    "code": "2G",
    "name": "Canada"
  },
  {
    "code": "2J",
    "name": "Canada"
  },
  {
    "code": "2N",
    "name": "Canada"
  },
  {
    "code": "TF",
    "name": "Switzerland"
  },
  {
    "code": "AL",
    "name": "United Kingdom"
  },
  {
    "code": 94,
    "name": "Brazil"
  },
  {
    "code": "L0",
    "name": "China"
  },
  {
    "code": "KL",
    "name": "South Korea"
  },
  {
    "code": "PM",
    "name": "Malaysia"
  },
  {
    "code": "6R",
    "name": "Australia"
  },
  {
    "code": "YF",
    "name": "Finland"
  },
  {
    "code": "LH",
    "name": "China"
  },
  {
    "code": "X0",
    "name": "Russia"
  },
  {
    "code": "JH",
    "name": "Japan"
  },
  {
    "code": "6D",
    "name": "Australia"
  },
  {
    "code": 97,
    "name": "Brazil"
  },
  {
    "code": "MG",
    "name": "Indonesia"
  },
  {
    "code": "4X",
    "name": "United States"
  },
  {
    "code": "LA",
    "name": "China"
  },
  {
    "code": "2C",
    "name": "Canada"
  },
  {
    "code": "LF",
    "name": "China"
  },
  {
    "code": "1B",
    "name": "United States"
  },
  {
    "code": 21,
    "name": "Canada"
  },
  {
    "code": "WU",
    "name": "Germany"
  },
  {
    "code": "W8",
    "name": "Germany"
  },
  {
    "code": "6H",
    "name": "Australia"
  },
  {
    "code": "3C",
    "name": "Mexico"
  },
  {
    "code": "8H",
    "name": "Chile"
  },
  {
    "code": "VG",
    "name": "France"
  },
  {
    "code": "9A",
    "name": "Brazil"
  },
  {
    "code": 47,
    "name": "United States"
  },
  {
    "code": 42,
    "name": "United States"
  },
  {
    "code": "J5",
    "name": "Japan"
  },
  {
    "code": "X7",
    "name": "Russia"
  },
  {
    "code": 17,
    "name": "United States"
  },
  {
    "code": "5L",
    "name": "United States"
  },
  {
    "code": "YJ",
    "name": "Finland"
  },
  {
    "code": "ZD",
    "name": "Italy"
  },
  {
    "code": "2S",
    "name": "Canada"
  },
  {
    "code": 48,
    "name": "United States"
  },
  {
    "code": "1F",
    "name": "United States"
  },
  {
    "code": "8F",
    "name": "Chile"
  },
  {
    "code": "5C",
    "name": "United States"
  },
  {
    "code": "YS",
    "name": "Sweden"
  },
  {
    "code": "1K",
    "name": "United States"
  },
  {
    "code": 93,
    "name": "Brazil"
  },
  {
    "code": "W3",
    "name": "Germany"
  },
  {
    "code": "W2",
    "name": "Germany"
  },
  {
    "code": "ZR",
    "name": "Italy"
  },
  {
    "code": "VH",
    "name": "France"
  },
  {
    "code": "WB",
    "name": "Germany"
  },
  {
    "code": "VV",
    "name": "Spain"
  },
  {
    "code": "6J",
    "name": "Australia"
  },
  {
    "code": "LD",
    "name": "China"
  },
  {
    "code": "TK",
    "name": "Czech Republic"
  },
  {
    "code": "YC",
    "name": "Belgium"
  },
  {
    "code": "6K",
    "name": "Australia"
  },
  {
    "code": "9C",
    "name": "Brazil"
  },
  {
    "code": "MM",
    "name": "Thailand"
  },
  {
    "code": "LU",
    "name": "China"
  },
  {
    "code": "MF",
    "name": "Indonesia"
  },
  {
    "code": "7C",
    "name": "New Zealand"
  },
  {
    "code": "1P",
    "name": "United States"
  },
  {
    "code": "XM",
    "name": "The Netherlands"
  },
  {
    "code": "2L",
    "name": "Canada"
  },
  {
    "code": 33,
    "name": "Mexico"
  },
  {
    "code": "1E",
    "name": "United States"
  },
  {
    "code": "3E",
    "name": "Mexico"
  },
  {
    "code": 40,
    "name": "United States"
  },
  {
    "code": "3K",
    "name": "Mexico"
  },
  {
    "code": "3Z",
    "name": "Mexico"
  },
  {
    "code": "5B",
    "name": "United States"
  },
  {
    "code": "PL",
    "name": "Malaysia"
  },
  {
    "code": "4R",
    "name": "United States"
  },
  {
    "code": 98,
    "name": "Brazil"
  },
  {
    "code": "WR",
    "name": "Germany"
  },
  {
    "code": 27,
    "name": "Canada"
  },
  {
    "code": "WE",
    "name": "Germany"
  },
  {
    "code": "YV",
    "name": "Sweden"
  },
  {
    "code": "L6",
    "name": "China"
  },
  {
    "code": "MJ",
    "name": "Indonesia"
  },
  {
    "code": "AA",
    "name": "United Kingdom"
  },
  {
    "code": "KP",
    "name": "South Korea"
  },
  {
    "code": "XW",
    "name": "USSR"
  },
  {
    "code": "1H",
    "name": "United States"
  },
  {
    "code": "VM",
    "name": "France"
  },
  {
    "code": "2K",
    "name": "Canada"
  },
  {
    "code": "XS",
    "name": "USSR"
  },
  {
    "code": "RF",
    "name": "Taiwan"
  },
  {
    "code": "1U",
    "name": "United States"
  },
  {
    "code": "1G",
    "name": "United States"
  },
  {
    "code": "LC",
    "name": "China"
  },
  {
    "code": "1T",
    "name": "United States"
  },
  {
    "code": "1S",
    "name": "United States"
  },
  {
    "code": "TM",
    "name": "Czech Republic"
  },
  {
    "code": "TE",
    "name": "Switzerland"
  },
  {
    "code": "JK",
    "name": "Japan"
  },
  {
    "code": 28,
    "name": "Canada"
  },
  {
    "code": "LP",
    "name": "China"
  },
  {
    "code": "V1",
    "name": "Yugoslavia"
  },
  {
    "code": "W6",
    "name": "Germany"
  },
  {
    "code": "4B",
    "name": "United States"
  },
  {
    "code": "TP",
    "name": "Czech Republic"
  },
  {
    "code": "6B",
    "name": "Australia"
  },
  {
    "code": "2A",
    "name": "Canada"
  },
  {
    "code": "SZ",
    "name": "Poland"
  },
  {
    "code": 35,
    "name": "Mexico"
  },
  {
    "code": "JU",
    "name": "Japan"
  },
  {
    "code": "2Y",
    "name": "Canada"
  },
  {
    "code": "7D",
    "name": "New Zealand"
  },
  {
    "code": "WZ",
    "name": "Germany"
  },
  {
    "code": "YK",
    "name": "Finland"
  },
  {
    "code": "YG",
    "name": "Finland"
  },
  {
    "code": "X4",
    "name": "Russia"
  },
  {
    "code": "4G",
    "name": "United States"
  },
  {
    "code": "TL",
    "name": "Czech Republic"
  },
  {
    "code": "5F",
    "name": "United States"
  },
  {
    "code": "2F",
    "name": "Canada"
  },
  {
    "code": "2X",
    "name": "Canada"
  },
  {
    "code": "JN",
    "name": "Japan"
  },
  {
    "code": "WK",
    "name": "Germany"
  },
  {
    "code": "WS",
    "name": "Germany"
  },
  {
    "code": "WN",
    "name": "Germany"
  },
  {
    "code": "6L",
    "name": "Australia"
  },
  {
    "code": "AF",
    "name": "United Kingdom"
  },
  {
    "code": "LK",
    "name": "China"
  },
  {
    "code": "LN",
    "name": "China"
  },
  {
    "code": "5Y",
    "name": "United States"
  },
  {
    "code": "SX",
    "name": "Poland"
  },
  {
    "code": "L9",
    "name": "China"
  },
  {
    "code": "JW",
    "name": "Japan"
  },
  {
    "code": "SS",
    "name": "Germany"
  },
  {
    "code": "5G",
    "name": "United States"
  },
  {
    "code": "PA",
    "name": "Philippines"
  },
  {
    "code": "5K",
    "name": "United States"
  },
  {
    "code": "JZ",
    "name": "Japan"
  },
  {
    "code": 18,
    "name": "United States"
  },
  {
    "code": "5D",
    "name": "United States"
  },
  {
    "code": "5W",
    "name": "United States"
  },
  {
    "code": "AD",
    "name": "United Kingdom"
  },
  {
    "code": "AK",
    "name": "United Kingdom"
  },
  {
    "code": "LY",
    "name": "China"
  },
  {
    "code": 12,
    "name": "United States"
  },
  {
    "code": "JA",
    "name": "Japan"
  },
  {
    "code": "1V",
    "name": "United States"
  },
  {
    "code": "5X",
    "name": "United States"
  },
  {
    "code": 20,
    "name": "Canada"
  },
  {
    "code": 25,
    "name": "Canada"
  },
  {
    "code": 57,
    "name": "United States"
  },
  {
    "code": "LS",
    "name": "China"
  },
  {
    "code": "2V",
    "name": "Canada"
  },
  {
    "code": "3S",
    "name": "Mexico"
  },
  {
    "code": "1L",
    "name": "United States"
  },
  {
    "code": "5J",
    "name": "United States"
  },
  {
    "code": "2T",
    "name": "Canada"
  },
  {
    "code": 44,
    "name": "United States"
  },
  {
    "code": "AB",
    "name": "United Kingdom"
  },
  {
    "code": "3J",
    "name": "Mexico"
  },
  {
    "code": 13,
    "name": "United States"
  },
  {
    "code": "RG",
    "name": "Taiwan"
  },
  {
    "code": "TN",
    "name": "Czech Republic"
  },
  {
    "code": "LM",
    "name": "China"
  },
  {
    "code": "LB",
    "name": "China"
  },
  {
    "code": "YE",
    "name": "Belgium"
  },
  {
    "code": "V2",
    "name": "Yugoslavia"
  },
  {
    "code": 43,
    "name": "United States"
  },
  {
    "code": "4U",
    "name": "United States"
  },
  {
    "code": "TH",
    "name": "Switzerland"
  },
  {
    "code": "7B",
    "name": "New Zealand"
  },
  {
    "code": "LV",
    "name": "China"
  },
  {
    "code": "LX",
    "name": "China"
  },
  {
    "code": 31,
    "name": "Mexico"
  },
  {
    "code": 50,
    "name": "United States"
  },
  {
    "code": "JT",
    "name": "Japan"
  },
  {
    "code": 10,
    "name": "United States"
  },
  {
    "code": "L7",
    "name": "China"
  },
  {
    "code": "4Z",
    "name": "United States"
  },
  {
    "code": "TW",
    "name": "Portugal"
  },
  {
    "code": "4L",
    "name": "United States"
  },
  {
    "code": "MB",
    "name": "India"
  },
  {
    "code": "JF",
    "name": "Japan"
  },
  {
    "code": "KN",
    "name": "South Korea"
  },
  {
    "code": "6F",
    "name": "Australia"
  },
  {
    "code": "PN",
    "name": "Malaysia"
  },
  {
    "code": "JM",
    "name": "Japan"
  },
  {
    "code": "XT",
    "name": "USSR"
  },
  {
    "code": "TB",
    "name": "Switzerland"
  },
  {
    "code": "J7",
    "name": "Japan"
  },
  {
    "code": "5U",
    "name": "United States"
  },
  {
    "code": "PB",
    "name": "Philippines"
  },
  {
    "code": 19,
    "name": "United States"
  },
  {
    "code": "JL",
    "name": "Japan"
  },
  {
    "code": "2D",
    "name": "Canada"
  },
  {
    "code": "1W",
    "name": "United States"
  },
  {
    "code": "AG",
    "name": "United Kingdom"
  },
  {
    "code": "5T",
    "name": "United States"
  },
  {
    "code": "3A",
    "name": "Mexico"
  },
  {
    "code": "L3",
    "name": "China"
  },
  {
    "code": "TJ",
    "name": "Czech Republic"
  },
  {
    "code": "MN",
    "name": "Thailand"
  },
  {
    "code": "KM",
    "name": "South Korea"
  },
  {
    "code": "5H",
    "name": "United States"
  },
  {
    "code": "X3",
    "name": "Russia"
  },
  {
    "code": "4M",
    "name": "United States"
  },
  {
    "code": "JY",
    "name": "Japan"
  },
  {
    "code": "2H",
    "name": "Canada"
  },
  {
    "code": "WG",
    "name": "Germany"
  },
  {
    "code": "L8",
    "name": "China"
  },
  {
    "code": 39,
    "name": "Mexico"
  },
  {
    "code": "JC",
    "name": "Japan"
  },
  {
    "code": "1Y",
    "name": "United States"
  },
  {
    "code": "TR",
    "name": "Hungary"
  },
  {
    "code": "WC",
    "name": "Germany"
  },
  {
    "code": "5A",
    "name": "United States"
  },
  {
    "code": "VW",
    "name": "Spain"
  },
  {
    "code": "ZA",
    "name": "Italy"
  },
  {
    "code": "JJ",
    "name": "Japan"
  },
  {
    "code": "3B",
    "name": "Mexico"
  },
  {
    "code": "9E",
    "name": "Brazil"
  },
  {
    "code": "1C",
    "name": "United States"
  },
  {
    "code": "JD",
    "name": "Japan"
  },
  {
    "code": "3Y",
    "name": "Mexico"
  },
  {
    "code": "JS",
    "name": "Japan"
  },
  {
    "code": "J0",
    "name": "Japan"
  },
  {
    "code": 34,
    "name": "Mexico"
  },
  {
    "code": "SN",
    "name": "Germany"
  },
  {
    "code": "5N",
    "name": "United States"
  },
  {
    "code": "4K",
    "name": "United States"
  },
  {
    "code": "WP",
    "name": "Germany"
  },
  {
    "code": "1D",
    "name": "United States"
  },
  {
    "code": "9H",
    "name": "Colombia"
  },
  {
    "code": "4F",
    "name": "United States"
  },
  {
    "code": "ZB",
    "name": "Italy"
  },
  {
    "code": "8Z",
    "name": "Venezuela"
  },
  {
    "code": "4C",
    "name": "United States"
  },
  {
    "code": "VB",
    "name": "Austria"
  },
  {
    "code": "W9",
    "name": "Germany"
  },
  {
    "code": "JG",
    "name": "Japan"
  },
  {
    "code": "1R",
    "name": "United States"
  },
  {
    "code": "2R",
    "name": "Canada"
  },
  {
    "code": "2U",
    "name": "Canada"
  },
  {
    "code": "PE",
    "name": "Philippines"
  },
  {
    "code": "TG",
    "name": "Switzerland"
  },
  {
    "code": "PD",
    "name": "Philippines"
  },
  {
    "code": 45,
    "name": "United States"
  },
  {
    "code": "YW",
    "name": "Sweden"
  },
  {
    "code": "W1",
    "name": "Germany"
  },
  {
    "code": "6A",
    "name": "Australia"
  },
  {
    "code": "W4",
    "name": "Germany"
  },
  {
    "code": "VX",
    "name": "Yugoslavia"
  },
  {
    "code": "3X",
    "name": "Mexico"
  },
  {
    "code": 54,
    "name": "United States"
  },
  {
    "code": "LL",
    "name": "China"
  },
  {
    "code": 16,
    "name": "United States"
  },
  {
    "code": "VK",
    "name": "France"
  },
  {
    "code": 51,
    "name": "United States"
  },
  {
    "code": 56,
    "name": "United States"
  },
  {
    "code": "VT",
    "name": "Spain"
  },
  {
    "code": "3M",
    "name": "Mexico"
  },
  {
    "code": "LW",
    "name": "China"
  },
  {
    "code": "8G",
    "name": "Chile"
  },
  {
    "code": "5S",
    "name": "United States"
  },
  {
    "code": "MP",
    "name": "Thailand"
  },
  {
    "code": "AJ",
    "name": "United Kingdom"
  },
  {
    "code": "ZP",
    "name": "Italy"
  },
  {
    "code": "LR",
    "name": "China"
  },
  {
    "code": "ZE",
    "name": "Italy"
  },
  {
    "code": "5Z",
    "name": "United States"
  },
  {
    "code": "5R",
    "name": "United States"
  },
  {
    "code": "ZF",
    "name": "Italy"
  },
  {
    "code": "3V",
    "name": "Mexico"
  },
  {
    "code": "4P",
    "name": "United States"
  },
  {
    "code": "VA",
    "name": "Austria"
  },
  {
    "code": "VN",
    "name": "France"
  },
  {
    "code": "MA",
    "name": "India"
  },
  {
    "code": "1Z",
    "name": "United States"
  },
  {
    "code": "YU",
    "name": "Sweden"
  },
  {
    "code": "WX",
    "name": "Germany"
  },
  {
    "code": "L2",
    "name": "China"
  },
  {
    "code": "3R",
    "name": "Mexico"
  },
  {
    "code": "6M",
    "name": "Australia"
  },
  {
    "code": 82,
    "name": "Venezuela"
  },
  {
    "code": 14,
    "name": "United States"
  },
  {
    "code": "VE",
    "name": "Austria"
  },
  {
    "code": "4N",
    "name": "United States"
  },
  {
    "code": 49,
    "name": "United States"
  },
  {
    "code": "XV",
    "name": "USSR"
  },
  {
    "code": "2M",
    "name": "Canada"
  },
  {
    "code": "PR",
    "name": "Malaysia"
  },
  {
    "code": "KR",
    "name": "South Korea"
  },
  {
    "code": "X5",
    "name": "Russia"
  },
  {
    "code": "TT",
    "name": "Hungary"
  },
  {
    "code": "3N",
    "name": "Mexico"
  },
  {
    "code": "5M",
    "name": "United States"
  },
  {
    "code": 58,
    "name": "United States"
  },
  {
    "code": "X8",
    "name": "Russia"
  },
  {
    "code": "VY",
    "name": "Yugoslavia"
  },
  {
    "code": 23,
    "name": "Canada"
  },
  {
    "code": "ZJ",
    "name": "Italy"
  },
  {
    "code": 15,
    "name": "United States"
  },
  {
    "code": "ZM",
    "name": "Italy"
  },
  {
    "code": 24,
    "name": "Canada"
  },
  {
    "code": "VS",
    "name": "Spain"
  },
  {
    "code": "X6",
    "name": "Russia"
  },
  {
    "code": "SP",
    "name": "Germany"
  },
  {
    "code": "5E",
    "name": "United States"
  },
  {
    "code": "6C",
    "name": "Australia"
  },
  {
    "code": "ZK",
    "name": "Italy"
  },
  {
    "code": "XN",
    "name": "The Netherlands"
  },
  {
    "code": "VL",
    "name": "France"
  },
  {
    "code": "VP",
    "name": "France"
  },
  {
    "code": "ZN",
    "name": "Italy"
  },
  {
    "code": "YT",
    "name": "Sweden"
  },
  {
    "code": "4A",
    "name": "United States"
  },
  {
    "code": "8X",
    "name": "Venezuela"
  },
  {
    "code": "9F",
    "name": "Colombia"
  },
  {
    "code": "2B",
    "name": "Canada"
  },
  {
    "code": 53,
    "name": "United States"
  },
  {
    "code": "3P",
    "name": "Mexico"
  },
  {
    "code": "SW",
    "name": "Poland"
  },
  {
    "code": 11,
    "name": "United States"
  },
  {
    "code": "8J",
    "name": "Chile"
  },
  {
    "code": "SV",
    "name": "Poland"
  },
  {
    "code": "6N",
    "name": "Australia"
  },
  {
    "code": "VJ",
    "name": "France"
  },
  {
    "code": "6S",
    "name": "Australia"
  },
  {
    "code": "WM",
    "name": "Germany"
  },
  {
    "code": "1X",
    "name": "United States"
  },
  {
    "code": "L4",
    "name": "China"
  },
  {
    "code": 29,
    "name": "Canada"
  },
  {
    "code": "TS",
    "name": "Hungary"
  },
  {
    "code": "3L",
    "name": "Mexico"
  },
  {
    "code": "6G",
    "name": "Australia"
  },
  {
    "code": "XU",
    "name": "USSR"
  },
  {
    "code": "3G",
    "name": "Mexico"
  },
  {
    "code": "X9",
    "name": "Russia"
  },
  {
    "code": "TD",
    "name": "Switzerland"
  }
]
